@import 'assets/scss/_mixins.scss';

.root {
    min-width: 100%;
    max-height: 160px;
    overflow-y: hidden;
}

.controller {
    position: fixed;
    top: -1px;
    color: var(--core60);
    background-color: var(--core5);
    width: 100%;
    z-index: 100;
    transition: opacity 0.6s ease;
}

.navigation {
    width: calc($largeLayout - 70px);
    display: flex;
    justify-content: space-between;
}
.menu {
    min-height: 100%;
    display: flex;
    .menuLink {
        padding-top: 32px;
    }
}
.wrapper {
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    -ms-overflow-style: -ms-autohiding-scrollbar;
}

// UNDERBAR AND ANIMATION
.underbar {
    z-index: -1;
    width: 100px;
    height: 1px;
    top: 57px;
    position: absolute;
    transition: left 0.5s ease, width 0.5s ease, background-color 0.8s ease-in-out;
}

@include screen('medium') {
    .root {
        display: none;
    }
}

.hide {
    display: none;
}
