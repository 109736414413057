$breakpoints: (
    'XXSmall': 0px,
    'extra-small': 360px,
    'small': 575px,
    'medium': 767px,
    'large': 991px,
    'extra-large': 1299px,
) !default;

$font-stack: 'Cadiz', Arial, sans-serif;
$font-weight: 340;
$font-weight-book: 220;
$font-weight-light: 1;
$settings: 'ss03';
$settings-numerals: 'tnum', 'lnum', 'ss03';
$light-text-color: var(--core5);
$dark-text-color: var(--core95);
$light-text-blok: var(--core20);
$dark-text-blok: var(--core80);

$standardLayout: 600px;
$largeLayout: 840px;

$light-theme: $font-stack, $font-weight, $settings, $light-text-color;
$dark-theme: $font-stack, $font-weight-book, $settings, $dark-text-color;
$light-theme-uni-text: $font-stack, $font-weight, $settings, $light-text-blok;
$dark-theme-uni-text: $font-stack, $font-weight-book, $settings, $dark-text-blok;

$duration-overlay: 0.1s;
$duration-modal: 0.5s;
$duration-navbar: 0.6s;
$duration-back-btn: 0.7s;
$duration-anchor-links: 0.8s;
$duration-close-btn: 0.9s;
$duration-progressbar: 1s;
$duration-hero: 1.1s;
$duration-image: 1.2s;

@media (prefers-reduced-motion) {
    $duration-overlay: 0.001ms;
    $duration-modal: 0.001ms;
    $duration-navbar: 0.001ms;
    $duration-back-btn: 0.001ms;
    $duration-anchor-links: 0.001ms;
    $duration-close-btn: 0.001ms;
    $duration-progressbar: 0.001ms;
    $duration-hero: 0.001ms;
    $duration-image: 0.001ms;
}
