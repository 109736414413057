@import '@/assets/scss/mixins';

.link {
    user-select: none;
    line-height: 26px;
    padding-right: 24px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 25px;
        left: 12;
        width: calc(100% - 24px);
        height: 1px;
        opacity: 0;
        z-index: -150;
        background-color: var(--hover-color);
        transition: background-color var(--timing) ease-in-out;
    }

    &.hover::before {
        opacity: 1;
    }
}

.rtl {
    cursor: pointer;
    line-height: 26px;
    font-weight: $font-weight-book;
    &:hover,
    &.active {
        color: var(--core95) !important;
    }
}

.active {
    color: var(--core95) !important;
}
