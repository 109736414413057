$duration: 250ms;

.nav {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    width: calc(100vw - 40px);
    left: 20px;
    max-width: 100%;
    z-index: 100;
    background-color: var(--core20);
    border-radius: 8px;
    height: 64px;
    bottom: 20px;
    transition: all $duration ease-in-out;
    -webkit-transition: all $duration ease-in-out;
    -moz-transition: all $duration ease-in-out;
    -o-transition: all $duration ease-in-out;
    -ms-transition: all $duration ease-in-out;

    i {
        color: var(--core60) !important;
        font-size: 32px;
    }

    .text {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 12px;
        justify-content: center;
        align-items: center;
        padding: 12px;
        i {
            font-size: 16px !important;
        }
        .burger {
            width: 20px;
            height: 16px;
        }
    }
}
.navlight {
    background-color: var(--core80);

    i {
        color: var(--core20) !important;
    }
}
.scrollingDown {
    width: 58px;
    height: 58px;
    left: calc(100vw - 80px);
    right: 20px;

    .menu {
        width: 0;
        opacity: 0;
    }
    .text {
        gap: 0;
    }
}
.expanded {
    height: 472px;
    width: calc(100vw - 38px);
    overflow: hidden;
    left: 20px;
    .text {
        display: flex;
        flex-direction: column;
        padding: 8px 20px;
        gap: 12px;
        i {
            font-size: 16px !important;
        }
        .burger {
            width: 20px;
            height: 16px;
        }
    }
    .row {
        width: 100%;
        height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        i {
            font-size: 16px;
        }
    }
    .close,
    .chapters {
        width: calc(100vw - 40px);
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 12px 0;
        border-top: 1px solid var(--core40);
        i {
            font-size: 16px;
        }

        .closeIcon {
            width: 32px;
            height: 32px;
            padding-top: 4px;
            padding-left: 6px;
        }
    }

    .currentRow {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 7px;
        text-decoration-color: var(--color);
    }
}
.open {
    animation: border-line $duration;
}

.menu {
    width: fit-content;
    transition: $duration ease-in-out;
    opacity: 1;
}

@keyframes border-line {
    from {
        border-top: 1px solid var(--core40);
        width: 50%;
    }
    to {
        border-top: 1px solid var(--core40);
        width: 100%;
    }
}
