@import '@/assets/scss/mixins';

$difference: calc((($largeLayout - $standardLayout) / 2) - 18px);

.wrapper {
    margin: 0 auto !important;
}
.contRoot,
.fullpage {
    max-width: 100%;
}
.large {
    min-width: $largeLayout;
    max-width: 100%;
    width: $largeLayout;
    &.left {
        transform: translateX(-$difference);
    }
    &.right {
        margin-left: $difference;
        margin-right: 0;
        transform: translateX($difference);
    }
    @media screen and (max-width: $largeLayout) {
        min-width: 100%;
    }
}
.standard {
    min-width: $standardLayout;
    max-width: $standardLayout;
    width: 100%;
    margin: 0 auto !important;
    @media screen and (max-width: $standardLayout) {
        min-width: 100%;
    }
}
@include screen('medium') {
    .large,
    .standard {
        min-width: 100%;
        max-width: 100%;
    }
    .wrapper {
        max-width: 100% !important;
    }
}
