@import '../../../assets/scss/mixins';

/* 
* light theme 
*/
.h1,
.h2,
.h3,
.h4,
.p,
.p_compressed,
.p_link,
.small,
.quote {
    @include theme($light-theme...);
}
.h1 {
    @include style-font(60px, 64px, -2.2px);
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
.h2 {
    @include style-font(36px, 44px, -1px);
}
.h3 {
    @include style-font(24px, 34px, -0.6px);
    font-feature-settings: $settings-numerals;
}
.h4 {
    font-weight: 700;
    @include style-font(20px, 30px, -0.4px);
}
.p {
    @include style-font(20px, 30px, -0.4px);
}
.p_compressed {
    @include style-font(20px, 24px, -0.4px);
    font-feature-settings: $settings-numerals;
}
.p_link {
    @include style-font(20px, 24px, -0.4px);
    text-decoration-line: underline;
}
.small {
    @include style-font(16px, 22px, -0.2px);
}
.quote {
    @include style-font(30px, 40px, -1.6px);
    font-weight: 1;
}
.article_filter {
    @include style-font(36px, 42px, -1px);
    font-weight: 700;
    font-feature-settings: $settings-numerals;
    color: $light-text-color;
}
.article_quote {
    @include style-font(30px, 36px, -0.8px);
    font-weight: 700;
    text-align: center;
    font-feature-settings: $settings;
    color: $light-text-color;
}
/*
* dark theme
*/
.h1_dark,
.h2_dark,
.h3_dark,
.p_dark,
.p_compressed_dark,
.p_link_dark,
.small_dark,
.quote_dark {
    @include theme($dark-theme...);
}
.h1_dark {
    @include style-font(60px, 64px, -2px);
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
.h2_dark {
    @include style-font(36px, 44px, -0.8px);
}
.h3_dark {
    @include style-font(24px, 34px, -0.4px);
    font-feature-settings: $settings-numerals;
}
.h4_dark {
    @include style-font(20px, 30px, -0.2px);
    font-feature-settings: $settings;
    color: $dark-text-color;
    font-weight: 500;
}
.p_dark {
    @include style-font(20px, 30px, -0.2px);
}
.p_compressed_dark {
    @include style-font(20px, 24px, -0.2px);
    font-feature-settings: $settings-numerals;
}
.p_link_dark {
    @include style-font(20px, 24px, -0.2px);
    font-feature-settings: $settings-numerals;
    text-decoration-line: underline;
}
.small_dark {
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: $settings-numerals;
}
.quote_dark {
    @include style-font(30px, 40px, -1.4px);
    font-weight: 1;
}
.article_filter_dark {
    @include style-font(36px, 42px, -0.8px);
    font-feature-settings: $settings-numerals;
    color: $dark-text-color;
    font-weight: 500;
}
.article_quote_dark {
    @include style-font(30px, 36px, -0.6px);
    text-align: center;
    font-feature-settings: $settings;
    color: $dark-text-color;
    font-weight: 500;
}
/*
* mobile
*/
@media (max-width: 700px) {
    %articleFilter {
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        font-feature-settings: $settings;
    }
    %small {
        font-size: 16px;
        line-height: 22px;
        font-feature-settings: $settings-numerals;
    }
    .h1 {
        @include style-font(48px, 54px, -1.4px);
    }
    .h1_dark {
        @include style-font(48px, 54px, -1.2px);
    }
    .h2 {
        @include style-font(30px, 36px, -0.8px);
    }
    .h2_dark {
        @include style-font(30px, 36px, -0.6px);
    }
    .h3 {
        @include style-font(24px, 34px, -0.6px);
        font-feature-settings: $settings-numerals;
        font-weight: 2200;
    }
    .h3_dark {
        @include style-font(24px, 34px, -0.6px);
        font-feature-settings: $settings-numerals;
    }

    .h4 {
        @include style-font(20px, 30px, -0.4px);
        font-weight: 700;
    }

    .h4_dark {
        @include style-font(20px, 30px, -0.2px);
        font-weight: 500;
    }

    .p {
        @include style-font(20px, 30px, -0.6px);
    }
    .p_dark {
        @include style-font(20px, 30px, -0.4px);
    }
    .p_compressed {
        @include style-font(20px, 24px, -0.6px);
        font-feature-settings: $settings-numerals;
    }
    .p_compressed_dark {
        @include style-font(20px, 24px, -0.4px);
        font-feature-settings: $settings-numerals;
    }
    .p_link {
        @include style-font(20px, 24px, -0.6px);
        text-decoration-line: underline;
        font-feature-settings: $settings-numerals;
    }
    .p_link_dark {
        @include style-font(20px, 24px, -0.4px);
        text-decoration-line: underline;
        font-feature-settings: $settings-numerals;
    }
    .small {
        @extend %small;
    }
    .small_dark {
        @extend %small;
    }
    .quote {
        @include style-font(24px, 32px, -1px);
        font-weight: 220;
    }
    .quote_dark {
        @include style-font(24px, 32px, -0.8px);
        font-weight: 1;
    }
    .article_filter {
        @extend %articleFilter;
        letter-spacing: -0.8px;
        color: $light-text-color;
        font-weight: 700;
    }
    .article_filter_dark {
        @extend %articleFilter;
        letter-spacing: -0.6px;
        color: $dark-text-color;
        font-weight: 500;
    }
    .article_quote {
        @include style-font(24px, 28px, -0.6px);
        text-align: center;
        font-feature-settings: $settings-numerals;
        color: $light-text-color;
        font-weight: 700;
    }
    .article_quote_dark {
        @include style-font(24px, 28px, -0.4px);
        text-align: center;
        font-feature-settings: $settings-numerals;
        color: $dark-text-color;
        font-weight: 700;
    }
}
