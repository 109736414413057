$core80: #c6c4cc;
.main {
    letter-spacing: -2.2px;
    padding-top: 192px;
    padding-bottom: 128px;
    @media (max-width: 840px) {
        height: 100%;
        display: flex;
        align-items: center;
    }
    @media (max-width: 767px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media (max-width: 575px) {
        padding-top: 30px;
        padding-bottom: 85px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 100vh;
    }
    .pretitle {
        color: $core80;
        font-size: 60px;
        font-weight: 400;
        line-height: 64px;
        @media (max-width: 840px) {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }
    .titleWrapper {
        max-width: 480px;
        .title {
            color: #edeff2;
            font-size: 60px;
            font-weight: 800;
            line-height: 60px;
            text-decoration: underline;
            text-decoration-color: #f20000;
            @media (max-width: 840px) {
                font-size: 48px;
                font-weight: 350;
                line-height: 54px;
                letter-spacing: -1.2px;
            }
            @media (max-width: 575px) {
                font-size: 48px;
                font-style: normal;
                font-weight: 350;
                line-height: 54px;
                letter-spacing: -1.2px;
            }
        }
    }
    .description {
        color: $core80;
        margin: 32px 0;
        @media (max-width: 840px) {
            margin-top: 32px;
            margin-bottom: 92px;
            font-size: 20px;
            font-weight: 350;
            line-height: 30px;
            letter-spacing: -0.4px;
        }
    }
    .button {
        cursor: pointer;
        border-radius: 25px;
        padding: 12px 24px;
        max-width: fit-content;
        border: 2px solid $core80;
        display: flex;
        align-items: center;
        .buttonText {
            color: $core80;
        }
    }
}
