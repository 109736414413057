@import 'variables';

@mixin screen($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin margin-bottom($length) {
    @content;
    &:not(:last-child) {
        margin-bottom: $length;
    }
}

@mixin theme($font, $weight, $settings, $text-color) {
    font-family: $font;
    font-weight: $weight;
    font-feature-settings: $settings;
    color: $text-color;
}

@mixin style-font($size, $height, $spacing) {
    font-size: $size;
    line-height: $height;
    letter-spacing: $spacing;
}
@mixin dark-scroll() {
    @content;
    $track: var(--core40);
    $background: var(--core10);
    scrollbar-width: thin;
    scrollbar-color: $track $background;
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        border: 6px solid $background;
        background-color: $background;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        outline: 0.5px solid $track;
        background: $track;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: var(--core60);
    }
    ::-webkit-scrollbar-thumb:active {
        background: var(--core80);
    }
}
@mixin light-scroll() {
    @content;
    $track: var(--core60);
    $background: var(--core90);
    scrollbar-width: thin;
    scrollbar-color: $track $background;
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        border: 6px solid $background;
        background-color: $background;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        outline: 0.5px solid $track;
        background: $track;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: var(--core40);
    }
    ::-webkit-scrollbar-thumb:active {
        background: var(--core20);
    }
}
