.languages {
    cursor: pointer;
    display: flex;
}
.wrapper {
    position: relative;
    position: relative;
    display: flex;
    align-items: center;
}

.languageContainer {
    position: relative;
    display: inline-flex;
}

.underbar {
    position: absolute;
    z-index: 10;
    bottom: 33px;
    height: 1px;
    width: 20px;
    transition: width 0.25s ease-out, left 0.25s ease-out;
    background-color: var(--core60);
}
